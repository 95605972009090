<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-secondary p-3 nav-box-shadow">
	<div class="container-fluid">
		<button class="navbar-toggler" (click)="toggleNav()" type="button" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<a class="navbar-brand" routerLink="/">
			<img src="../../../assets/images/lumiron-bubblesoccer-logo.svg" width="240" height="52" alt="" aria-label="Navigiere zu home">
		</a>
		<div [ngbCollapse]="(isNavCollapsed | async) != false" class="collapse navbar-collapse justify-content-end" id="navbar">
			<ul class="navbar-nav mb-2 mb-lg-0">
				<li class="nav-item"><a routerLink="/home" (click)="isNavCollapsed.next(true)" routerLinkActive="active" class="nav-link text-white">Home</a></li>
				<li class="nav-item"><a routerLink="/angebot" (click)="isNavCollapsed.next(true)" routerLinkActive="active" class="nav-link text-white ">Angebot</a></li>
				<li class="nav-item"><a routerLink="/faq" (click)="isNavCollapsed.next(true)" routerLinkActive="active" class="nav-link text-white ">FAQ</a></li>
				<li class="nav-item"><a routerLink="/ueber-uns" (click)="isNavCollapsed.next(true)" routerLinkActive="active" class="nav-link text-white ">Über uns</a></li>
				<li class="nav-item"><a routerLink="/kontakt" (click)="isNavCollapsed.next(true)" routerLinkActive="active" class="nav-link text-white ">Kontakt</a></li>
			</ul>
		</div>
	</div>
</nav>