import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CloudinaryModule, lazyload, placeholder } from '@cloudinary/ng';
import { Cloudinary, CloudinaryImage } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import ICloudinaryConfigurations from '@cloudinary/url-gen/config/interfaces/Config/ICloudinaryConfigurations';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, fromEvent, map, startWith, withLatestFrom } from 'rxjs';
import { CtaComponent } from '../shared/cta/cta.component';
import { Meta } from '@angular/platform-browser';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
@Component({
  standalone: true,
  selector: 'lumiron-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgOptimizedImage, CtaComponent, NgbCarouselModule, CloudinaryModule, RouterModule],
})
export class HomeComponent {
  private readonly cld = new Cloudinary({ cloud: {cloudName: 'lumiron'}} as ICloudinaryConfigurations);

  private readonly image$ = new BehaviorSubject<CloudinaryImage>(this.cld.image('bubbles-in-garden'));
  private readonly headerImageDimensions$ = fromEvent(window, 'resize').pipe(
    startWith(null),
    map(() => ({width: window.innerWidth, height: window.innerHeight - 94}))
  );

  protected readonly headerImage$ = this.headerImageDimensions$.pipe(
    withLatestFrom(this.image$),
    map(([dimensions, image]) => {
      const imageCopy = cloneDeep(image);
      
      return {image: imageCopy.resize(fill().width(dimensions.width).height(dimensions.height)), dimensions: dimensions};
    })
  );
  protected readonly headerImagePlugins = [placeholder({mode: 'blur'})];
  protected readonly imagePlugins = [lazyload()];
  
  protected logoTva = this.cld.image('review-logos/tva');
  protected logoSgw = this.cld.image('review-logos/sgw');

  protected readonly reviews = [
    {
      image: null,
      name: 'Tom',
      text: `Wir waren mit einem JGA bei Lumiron Bubble Soccer. Die Jungs sind wirklich super. 
            Sie haben tolle Spieleideen mit dem Bubble Soccer, sind auf alle Wünsche eingegangen. 
            Die Kommunikation im voraus für die Planung des Event war sehr gut.Es hat riesen Spaß gemacht. 
            DANKE EUCH, macht weiter so.`
    },
    {
      image: null,
      name: 'Kathrin Meier',
      text: `Ich habe bei Lumiron eine Partie Bubble Soccer für meine Klasse gebucht und es war ein voller Erfolg! 
            Sie kümmerten sich sogar um einen geeignet Platz, damit ich auf der Schulreise keinen Umweg machen muss. 
            Die Organisation hat perfekt geklappt. Alle Schüler hatten grossen Spass. Absolut empfehlenswert!`
    },
    {
      image: {
        image: this.logoTva,
        width: '148px',
        height: '56px',
      },
      name: 'Roman Inauen',
      text: `Im Zuge vom Sommersportlager des TV Appenzell wurden die Bubbles gemietet. 
            Die Bubbles brachten viel Freude von Jung bis Alt. Ebenso war das Material in einwandfreiem und sauberem Zustand. 
            Die Handhabung bei der Miete war dabei unkompliziert und sehr freundlich. 
            Sehr empfehlenswert.`,
    },
    {
      image: {
        image: this.logoSgw,
        width: '342px',
        height: '192px'
      },
      name: 'Dominik Gubser',
      text: `Mit unserem Verein, der Sportgruppe Wangs, durften wir einen sportlichen und spassigen Abend mit LUMIRON erleben.
            Luca und Aaron haben uns zuerst in die Künste des Bubble Soccers eingeführt, bevor es dann mit einem Mini-Turnier 
            so richtig zur Sache ging. Vielen Dank für den tollen Anlass`,
    }
  ];

  constructor(private meta: Meta){
    this.meta.addTags([
      {name: 'description', content: 'Bubble Soccer ist das perfekte Erlebnis für Ihr Event. Es garantiert Spass, Teamgeist und eine Menge Action.'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
