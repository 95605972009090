import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, filter, map, take, tap } from 'rxjs';

@Component({
  standalone: true,
  selector: 'lumiron-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, NgbCollapseModule]
})
export class NavComponent implements OnInit {
  private readonly router = inject(Router);
  protected readonly isNavCollapsed = new BehaviorSubject<boolean>(true);
  
  ngOnInit(): void {
    this.router.events.pipe(
      filter((e): e is NavigationStart => e instanceof NavigationStart),
      tap(() => window.scrollTo(0, 0))
    ).subscribe();
  }

  public toggleNav(): void {
    this.isNavCollapsed.pipe(
      take(1),
      map(isNavCollapsed => !isNavCollapsed),
      tap(isNavCollapsed => this.isNavCollapsed.next(isNavCollapsed))
    ).subscribe();
  }
}
