<div class="lumiron-hero-header">
    <div class="placeholder"></div>
    <div class="content">
    @if (headerImage$ | async; as headerImage) {
        <ng-container>
            <advanced-image class="image" [cldImg]="headerImage.image" [plugins]="headerImagePlugins" [width]="'100%'" [height]="headerImage.dimensions.height + 'px'"  [alt]="'Bubbles'"></advanced-image>
        </ng-container>
    }
        
        <div class="text d-flex flex-column justify-content-center align-items-center text-white">
            <h1>Fussball. Nur verrückter</h1>
            <a class='btn btn-lg btn-light mb-5' [routerLink]="'/angebot'">GLEICH ZUM ANGEBOT</a>
        </div>
    </div>
</div>

<div class="container">
    <div class="row my-lg-5 py-5">
        <div class="text-justify col-12 col-lg-6 mb-4 pe-lg-5">
            <h2>Wieso Bubble Soccer</h2>
            <p>
                <span class="badge rounded-pill bg-secondary me-1">Spass</span>
                <span class="badge rounded-pill bg-secondary me-1">Action</span>
                <span class="badge rounded-pill bg-secondary">Teamgeist</span>
            </p>
            <p>
                Bubble Soccer ist das perfekte Erlebnis für Ihr Event. Es garantiert Spass, Teamgeist und eine Menge Action.
            </p>
            <p>
                Die transparenten Bubbles werden wie ein Rucksack übergestreift. Kopf und Oberkörper sind dadurch vollständig bedeckt, 
                während die Beine weiterhin frei sind. So können die Spieler den Ball wie beim normalen Fussball kicken. 
                Die aufblasbaren Bubbles schützen vor Verletzungen und sorgen für reichlich Action auf dem Spielfeld. 
                Denn die weiche Polsterung ermöglicht Zusammenstöße ohne Verletzungsgefahr.
            </p>				
        </div>
        <div class="text-justify col-12 col-lg-6 ps-lg-5">
            <h2>Wieso wir</h2>
            <p>
                <span class="badge rounded-pill bg-secondary me-1">Regional</span>
                <span class="badge rounded-pill bg-secondary me-1">All inkl Angebot</span>
                <span class="badge rounded-pill bg-secondary">Motiviert</span>
            </p>
            <p>
                Wir vermieten nicht nur Bubbles, sondern ein Erlebnis. In unserem Angebot inbegriffen sind:
            </p>
            <ul>
                <li>Gesamte Organisation</li>
                <li>Bubbles und sonstige Utensilien (Bälle, Tore, etc)</li>
                <li>Bereitstellung eines Platzes</li>
                <li>Programm (Aufwärmen, Spiele, Match)</li>
                <li>Schiedsrichter</li>
                <li>Fotos</li>
            </ul>
            <p>Sie müssen nichts weiter tun als zum richtigen Zeitpunkt am richtigen Ort zu sein.</p>				
        </div>
    </div>
    <div class="py-5 bg-secondary">
        <ngb-carousel>
        @for(review of reviews; track review) {
            <ng-template ngbSlide>
                <div class="reviewer-image d-flex justify-content-center">
                @if(review.image) {
                    <advanced-image 
                    [width]="review.image.width" 
                    [height]="review.image.height" 
                    [cldImg]="review.image.image" 
                    [plugins]="imagePlugins"
                    [alt]="''">
                </advanced-image>
                }
                </div>
                <div class="carousel-caption">
                    <p class="reviewer-name">{{review.name}}</p>
                    <div class="pb-3">
                        <p>{{review.text}}</p>
                    </div>
                </div>
            </ng-template>
        }
        </ngb-carousel>
    </div>

    <lumiron-cta [title]="'Überzeugt?'" [buttonText]="'ZUM ANGEBOT'" [link]="'/angebot'"></lumiron-cta>
</div>